import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import React from 'react'
import { hydrateRoot } from 'react-dom/client'

const env = import.meta.env.VITE_NODE_ENV

if (env == 'prod') {
  Sentry.init({
    dsn: 'https://807728172b9845ccaabc22eb5c0518e6@glitchtip.fr8.in/21',
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    environment: env,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
  })
}

React.startTransition(() => {
  hydrateRoot(document.getElementById('root')!, <RemixBrowser />)
})
